import { configJSONNodeType, ConfigNode } from "./ConfigNode";

export class BoardGUIDefinitions {
    private static BOARD_CONFIG_NODE: ConfigNode = new ConfigNode({
        nodeName: "Board",
        MinTileHeightInPX: "40",
        children: [],
    });

    /**
     * Das "mehr" Menü für zusätzliche Optionen im Footer. Seperat definiert, da es auch einzeln auf diversen Seiten verwendet wird.
     * @private
     */
    private static MORE_FOOTERTABS_DEFAULT: configJSONNodeType = {
        nodeName: "FooterTab",
        children: [
            {
                nodeName: "SubMenu",
                children: [
                    {
                        nodeName: "PageLink",
                        Action: "logout",
                        children: [],
                        I18nLabel: "MobileApp.logout",
                        NavigationPath: "login",
                        Name: "Logout",
                    },
                ],
                Position: "fixed",
                Orientation: "above",
                Name: "moreFooterbarTabs",
            },
        ],
        Name: "more",
    };

    /**
     * Footer-Config für das Board
     * @private
     */
    private static FOOTER_CONFIG_NODE: ConfigNode = new ConfigNode({
        nodeName: "FooterTabBar",
        children: [
            {
                nodeName: "FooterTab",
                children: [],
                isSVG: "true",
                NavigationPath: "index",
                Name: "index",
            },
            {
                nodeName: "FooterTab",
                children: [],
                isSVG: "true",
                NavigationPath: "sync",
                Name: "sync",
            },
            BoardGUIDefinitions.MORE_FOOTERTABS_DEFAULT,
        ],
        Name: "Footerbar",
    });

    /**
     * GUI für das Boardelement, wird aktuell nicht konfigurierbar
     */
    public static getBoardGUIDefinitions(): ConfigNode {
        return BoardGUIDefinitions.BOARD_CONFIG_NODE;
    }

    /**
     * Definition des Footers mit seinen drei Elementen. Änderungen am Footer müssten in diesem JSON eingestellt werden
     */
    public static getFooterDefinition() {
        return BoardGUIDefinitions.FOOTER_CONFIG_NODE;
    }

    public static getMoreFooterTabDefault(): ConfigNode {
        return new ConfigNode(BoardGUIDefinitions.MORE_FOOTERTABS_DEFAULT);
    }
}
