import { GUIElement } from "../GUIElement";
import { EntityValue } from "../../entities/values/EntityValue";
import { FormField, formFieldChangeEventType, FormFieldMode } from "./FormField";
import { FormButton } from "./FormButton";
import { FormFieldFactory } from "./FormFieldFactory";
import { SchemaDataType } from "../../common/schema/SchemaDataType";
import "./form.less";
import { TimeRecordingDetailController } from "../../domain/time_recording/TimeRecordingDetailController";
import { TimeRecord } from "../../domain/time_recording/TimeRecord";

export type formChangeEventType = {
    formName: string;
    entityId: string;
    fieldName: string;
    value: EntityValue;
    formValues: { [key: string]: EntityValue } | TimeRecord;
};

export type formChangeCallbackType = (changeEvent: formChangeEventType) => void;

export type formSubmitEventType = {
    form: string;
    buttonName: string;
    formValues: { [key: string]: EntityValue };
};

export type formSubmitCallbackType = (changeEvent: formSubmitEventType) => void;

export class Form implements GUIElement {
    private name: string;

    private layoutElements: GUIElement[] = [];

    private formFieldList: FormField[] = [];

    private formFields: { [key: string]: FormField } = {};

    private formButtons: { [key: string]: FormButton } = {};

    private changeCallback: formChangeCallbackType;

    private submitCallback: formSubmitCallbackType;

    public setName(name: string): Form {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public addLayoutElement(layoutElement: GUIElement): Form {
        this.layoutElements.push(layoutElement);
        return this;
    }

    public createFormFieldFactory(): FormFieldFactory {
        return new FormFieldFactory().onCreateFormField(this.registerField.bind(this));
    }

    public registerField(formField: FormField): Form {
        this.formFieldList.push(formField);
        formField.onChange(this.formFieldChanged.bind(this));
        return this;
    }

    public registerButton(formButton: FormButton): Form {
        this.formButtons[formButton.getName()] = formButton;
        formButton.onClick(this.formButtonClicked.bind(this));
        return this;
    }

    public onFieldChange(changeCallback: formChangeCallbackType): Form {
        this.changeCallback = changeCallback;
        return this;
    }

    public onSubmit(submitCallback: formSubmitCallbackType): Form {
        this.submitCallback = submitCallback;
        return this;
    }

    public hasFields(): boolean {
        return this.formFieldList.length > 0;
    }

    public compose($parent: JQuery): void {
        // FormFields per Name merken (Namen werden erst später gesetzt, so dass Name beim create/register noch undefiniert)
        this.formFieldList.forEach(
            (formField) => (this.formFields[formField.getName()] = formField),
        );

        const $formTag = $("<form>")
            .appendTo($parent)
            .on("submit", function (event) {
                event.preventDefault();
            });

        for (let i = 0; i < this.layoutElements.length; i++) {
            this.layoutElements[i].compose($formTag);
        }
    }

    private readFormValues(): { [key: string]: EntityValue } {
        const formValues: { [key: string]: EntityValue } = {};

        this.formFieldList.forEach((formField) => {
            if (formField.getMode() == FormFieldMode.EDIT && !formField.isReadOnly()) {
                formValues[formField.getName()] = formField.getValue();
            }
        });

        return formValues;
    }

    private formFieldChanged(changeEvent: formFieldChangeEventType): void {
        const formValues = this.readFormValues();

        if (this.changeCallback) {
            this.changeCallback({
                formName: this.name,
                entityId: changeEvent.entityId,
                fieldName: changeEvent.name,
                value: changeEvent.value,
                formValues: formValues,
            });
        }
    }

    private formButtonClicked(clickEvent: { name: string }): void {
        this.submit(clickEvent.name);
    }

    public submit(buttonName: string = "submit"): void {
        const formValues = this.readFormValues();

        if (this.changeCallback) {
            this.submitCallback({
                form: this.name,
                buttonName: buttonName,
                formValues: formValues,
            });
        }
    }

    getComponentChildren(): GUIElement[] {
        return this.layoutElements;
    }
}
