import { GUIElement } from "../GUIElement";

export class FormButton implements GUIElement {
    private name: string;

    private label: string;

    private clickCallback: (clickEvent: { name: string }) => void;

    public setName(name: string): FormButton {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setLabel(label: string): FormButton {
        this.label = label;
        return this;
    }

    public onClick(clickCallback: (clickEvent: { name: string }) => void): FormButton {
        this.clickCallback = clickCallback;
        return this;
    }

    public compose($parent: JQuery): void {
        $("<input>")
            .appendTo($parent)
            .attr({
                type: "button",
                name: this.name,
                value: this.label,
            })
            .addClass("formbutton")
            .on("click", this.buttonClicked.bind(this));
    }

    private buttonClicked(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickCallback) {
            this.clickCallback({ name: this.getName() });
        }
    }

    getComponentChildren(): GUIElement[] {
        return [];
    }
}
