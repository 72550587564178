import { Entity } from "../../../entities/Entity";
import { Schema } from "../../../common/schema/Schema";
import { IdGenerator } from "../../../util/text/IdGenerator";
import { TimesheetEntry } from "./TimesheetEntry";
import { AttributeDefinition } from "../../../common/schema/AttributeDefinition";
import { StringValue } from "../../../entities/values/StringValue";
import { EntityValue } from "../../../entities/values/EntityValue";
import { DurationValue } from "../../../entities/values/DurationValue";

export class Task implements TimesheetEntry {
    private taskEntity: Entity;

    constructor(schema: Schema, bookingObject?: object, checksum?: number) {
        const typeSubtypeDefinition = schema.getTypeSubtypeDefinition("TimesheetEntry", "Tasks");
        let bookingValues = null;
        if (bookingObject) {
            bookingValues = bookingObject;
        } else {
            bookingValues = {
                oid: IdGenerator.createId() + "_Task",
                typ: "TimesheetEntry",
                subtyp: "Tasks",
            };
        }
        if (checksum) {
            bookingValues["checksum"] = checksum;
        }
        this.taskEntity = new Entity(typeSubtypeDefinition, bookingValues);
        this.taskEntity.scanWithPrefix(bookingValues, "task");

        this.extractPspPath(this.taskEntity.getValue("pspPath").getString());
    }

    public getId(): string {
        return this.taskEntity.getString("task__oid");
    }

    public getSubtype(): string {
        return this.taskEntity.getString("task__subtyp");
    }

    public getAttributeDefinition(name: string): AttributeDefinition {
        return this.taskEntity.getTypeSubtypeDefinition().getAttributeDefinition(name);
    }

    public reduceRemainingEffort(value: number) {
        const orignalValue = this.taskEntity.getNumber("task__remainingDuration");

        const durationValue = new DurationValue(Math.max(0, orignalValue - value));

        this.taskEntity.setValue("task__remainingDuration", durationValue);
    }

    public getRemainingEffort(): number {
        return this.taskEntity.getNumber("task__remainingDuration");
    }

    public setRemainingEffort(value: number) {
        const durationValue = new DurationValue(value);

        this.taskEntity.setValue("task__remainingDuration", durationValue);
    }

    /**
     *
     *
     */
    public getTargetSubtype(): string {
        return this.taskEntity.getString("task__subtyp");
    }

    public getName() {
        return this.taskEntity.getString("task__name");
    }

    public getPath() {
        return this.taskEntity.getString("task__name");
    }

    /**
     * @param name Aufgaben-Attributename
     * @return Aufgaben-Attributswert
     */
    public getValue(name: string): EntityValue {
        // AppConsole.log("[Task.getValue()] "+name);
        if (name === "grandParentName" || name === "parentName") {
            return this.taskEntity.getValue(name);
        }
        const sameNameTaskAttributes = ["name", "oid", "subtype"];
        if (sameNameTaskAttributes.indexOf(name) !== -1) {
            return this.taskEntity.getValue("task__" + name);
        } else {
            return this.taskEntity.getValue(name);
        }
    }

    public getBookableObjectValue(name: string): string {
        return this.taskEntity.getString(name);
    }

    public toValueObject(): object {
        return this.taskEntity.toValueObject();
    }

    public getDisplayPSPPath(): string {
        if (this.getGrandParentName() == this.getParentName()) {
            return this.getGrandParentName();
        }
        return this.getGrandParentName() + " > " + this.getParentName();
    }

    public getGrandParentName(): string {
        return this.taskEntity.getValue("grandParentName").getString();
    }

    public getParentName(): string {
        return this.taskEntity.getValue("parentName").getString();
    }

    public getBillability(): string {
        return this.taskEntity.getValue("chargeability").getString();
    }

    getExpensePlanningType(): string {
        return this.taskEntity.getValue("expensePlanningType").getString();
    }

    public getBookedDuration(): number {
        return this.taskEntity.getNumber("task__bookedDuration");
    }

    public setBookedDuration(value: number) {
        const durationValue = new DurationValue(value);

        this.taskEntity.setValue("task__bookedDuration", durationValue);
    }

    reduceBookedDuration(diffinMin: number) {
        const orignalValue: number = this.taskEntity.getNumber("task__bookedDuration");

        const durationValue = new DurationValue(Math.max(0, orignalValue - diffinMin));

        this.taskEntity.setValue("task__bookedDuration", durationValue);
    }

    private extractPspPath(rawPspPath: string): void {
        const pathArray: [string] = JSON.parse(rawPspPath);
        pathArray.forEach((pspRawEntry, index) => {
            const pspEntry: { isGrandParent: boolean; name: string } = JSON.parse(pspRawEntry);

            if (pspEntry.isGrandParent) {
                this.setGrandParentName(pspEntry.name);
            }

            if (index === pathArray.length - 1) {
                this.setParentName(pspEntry.name);
            }
        });
    }

    private setGrandParentName(grandParentName: string): void {
        this.taskEntity.setValue("grandParentName", new StringValue(grandParentName));
    }

    private setParentName(parentName: string): void {
        // wenn Projekt == Unterprojekt brauchen wir in der App nicht beides anzuzeigen, l�schen daher hier das Unterprojekt
        if (this.getGrandParentName() !== parentName) {
            this.taskEntity.setValue("parentName", new StringValue(parentName));
        }
    }
}
